const _0 = "0px"
const _1 = "4px"
const _2 = "8px"
const _3 = "12px"
const _4 = "16px"
const _5 = "20px"
const _6 = "24px"
const _8 = "32px"
const _10 = "40px"
const _12 = "48px"
const _14 = "56px"
const _15 = "60px"
const _16 = "64px"
const _18 = "72px"
const _20 = "80px"
const _24 = "96px"
const _32 = "126px"
const _40 = "160px"
const _48 = "192px"
const _56 = "224px"
const _64 = "256px"
const _72 = "320px"
const _zero = "0px"
const _4xs = "1px"
const _3xs = "2px"
const _2xs = "4px"
const _xs = "6px"
const _sm = "8px"
const _md = "12px"
const _lg = "16px"
const _xl = "20px"
const _2xl = "24px"
const _3xl = "32px"
const _4xl = "40px"
const _5xl = "48px"
const _6xl = "64px"
const _7xl = "80px"
const _8xl = "120px"
const _9xl = "150px"
const _10xl = "256px"
const _px = "1px"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "8": _8, "10": _10, "12": _12, "14": _14, "15": _15, "16": _16, "18": _18, "20": _20, "24": _24, "32": _32, "40": _40, "48": _48, "56": _56, "64": _64, "72": _72, "zero": _zero, "4xs": _4xs, "3xs": _3xs, "2xs": _2xs, "xs": _xs, "sm": _sm, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl, "8xl": _8xl, "9xl": _9xl, "10xl": _10xl, "px": _px,  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _8, _10, _12, _14, _15, _16, _18, _20, _24, _32, _40, _48, _56, _64, _72, _zero, _4xs, _3xs, _2xs, _xs, _sm, _md, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl, _8xl, _9xl, _10xl, _px }